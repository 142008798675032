.txt-forget {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.2px;
  color: #000000;
}

.txt-indicador {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: -0.2px;
  color: #000000;
  margin-bottom: 10px;
}

.overlay {
  box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px,
    rgb(0 0 0 / 30%) 0px 3px 7px -3px;
  border-radius: 7px;
  padding: 24px 0;
  position: absolute;
  top: -1.4rem;
  background: #fff;
  width: 75%;
}

.modal-txt {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.2px;
  color: #000000;
  padding: 0px 45px;
}
