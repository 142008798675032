.lbl-green {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #0BA783;
}

.filtro-activo {
    box-shadow: 0px 0px 15px #f00;
}

@media only screen and (max-width: 480px) {
    .Container-card.atm .Card-info {
        padding: 10px;
        margin-left: 8px !important;
    }
    .Container-card.atm .Card-info .txt-info {
        font-size: 12px;
    }
}