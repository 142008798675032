.txt-historico {
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 18px;
    letter-spacing: -0.2px;
    color: #0ba783;
    margin-bottom: 0;
}

.container-select-2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 85%;
    margin: 0 auto;
}

select.select-departamento-2 {
    border: none;
    box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px;
    width: 100%;
    border-radius: 10%;
    height: 30px;
    text-align: center;
}

select.select-departamento-2:focus-visible {
    outline: none !important;
}

.container-txt-select {
    width: 40%;
}

.container-option {
    width: 52%;
}

.txt-ubication {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.2px;
    color: #000000;
    border-bottom: 1px solid #000000;
    cursor: pointer;
    margin-bottom: 0;
    line-height: 22px;
}

.txt-depositario {
    font-style: normal;
    font-weight: 800;
    font-size: 9px;
    line-height: 12px;
    text-align: center;
    letter-spacing: -0.2px;
    color: #000000;
    margin-bottom: 0;
}

.txt-title-ubi {
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 18px;
    letter-spacing: -0.2px;
    color: #000000;
    margin-bottom: 0;
}

.div-depositario {
    background: #0ba783;
    height: 35px;
    width: 160px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    padding: 22px 42px;
}

.container-location {
    width: 100%;
    justify-content: space-evenly;
}

img.location {
    width: 25px;
    height: 25px;
}

.txt-content-ubi {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
    letter-spacing: -0.2px;
    color: #000000;
    margin-bottom: 0;
}

.tab-panel {
    display: flex;
}

.tab-panel span {
    border-bottom: 1px solid #000;
    font-size: 12px;
    cursor: pointer;
    padding: 0px 4px;
    margin: 0px 2px;
    margin-bottom: 1px;
}

.tab-panel span.active {
    border-bottom: 2px solid #FADA22;
    font-weight: 700;
    margin-bottom: 0px;
}