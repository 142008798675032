.txt-login {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.2px;
    color: #000000;
    width: 50%;
}

.txt-user {
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 18px;
    letter-spacing: -0.2px;
    color: #000000;
}

.container-input-title {
    display: flex;
    flex-direction: row;
    align-content: center;
    position: relative;
}

.txt-user {
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 18px;
    letter-spacing: -0.2px;
    color: #000000;
    margin: 0px;
    position: absolute;
    top: 16px;
    left: 33px;
}

.input-user {
    border: none;
    border-bottom: 2px solid #000000;
}

.input-user:focus-visible {
    outline: none !important;
    border-bottom: 2px solid #fada22;
}

.container-inputs {
    padding: 0px 50px;
    position: relative;
}

.btn-show-pass {
    border: none;
    background: transparent;
    position: absolute;
    right: 61px;
    top: 2rem;
}

.link-forgot-pass {
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 18px;
    letter-spacing: -0.2px;
    text-decoration-line: underline;
    color: #000000;
}

.btn-login {
    background: #fada22;
    border: none;
    border-radius: 10px;
    width: 206px;
    height: 33px;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.2px;
    color: #000000;
}

.link-bottom {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.2px;
    text-decoration-line: underline;
    color: #000000;
}