#root {
    font-family: 'Open Sans', sans-serif;
}

#root {
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
}

.container-wpa {
    max-width: 500px;
    margin: auto;
}

.regresar_btn {
    text-decoration: none;
    color: #000;
    font-weight: 600;
    font-size: 14px;
}

.ico-prev {
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    background: url('globalassets/img/ico-prev.png') no-repeat center;
    background-size: contain;
    margin: 0px;
}

.ico-prev2 {
    display: inline-block;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    background: url('globalassets/img/ico-prev2.png') no-repeat center;
    background-size: contain;
    margin: 0px;
}

.div-regresar {
    padding: 0px 50px;
    margin-bottom: 30px;
}

.btn-loading {
    background: transparent;
    background-image: url('globalassets/img/loader-button.gif');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 60%;
    font-size: 0px;
    color: transparent;
}

.register-header {
    position: relative;
}

.register-header .back-button {
    position: absolute;
    top: 7px;
    left: 5px;
    cursor: pointer;
}

.ico-backbutton {
    display: inline;
    width: 24px;
    height: 24px;
    vertical-align: middle;
    background: url('./globalassets/img/ico-prev.svg') no-repeat center;
    background-repeat: no-repeat;
    background-size: contain;
}

.backbutton-header {
    position: absolute;
    top: 22px;
    left: -15px;
    cursor: pointer;
}

.item-novedad {
    position: relative;
    display: block;
}

.lista-novedades {
    padding: 0px 30px;
}

.item-novedad {
    margin-bottom: 30px;
}

.item-novedad .item-novedad-header {
    background-color: #000000;
    border-radius: 5px;
    color: #fff;
    padding: 8px;
    text-align: center;
    font-weight: 700;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.85596 10.89L8.85596 14.952' stroke='%239063CD' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8.85596 5.95201L8.85596 10.89' stroke='%23F5B6CD' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8.85596 2.76003L8.85596 5.95203' stroke='%2300CCEA' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M4.69824 11.958L2.49624 9.79201' stroke='%2300C389' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11.7539 12.96L9.54591 15.138C9.33153 15.3491 9.04275 15.4674 8.74191 15.4674C8.44106 15.4674 8.15229 15.3491 7.93791 15.138V15.138L4.70391 11.958' stroke='%23FF7F37' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15 9.79208L11.754 12.9601' stroke='%23FDDA24' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: left 10px center;
    background-size: 25px;
    cursor: pointer;
}

.item-novedad.open .item-novedad-header {
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.14355 7.10997L9.14355 3.04797' stroke='%239063CD' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M9.14355 12.048L9.14355 7.10999' stroke='%23F5B6CD' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M9.14355 15.24L9.14355 12.048' stroke='%2300CCEA' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M13.3018 6.04199L15.5038 8.20799' stroke='%2300C389' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6.24609 5.03995L8.45409 2.86195C8.66847 2.65089 8.95725 2.53259 9.25809 2.53259C9.55894 2.53259 9.84771 2.65089 10.0621 2.86195V2.86195L13.2961 6.04195' stroke='%23FF7F37' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M3 8.20792L6.246 5.03992' stroke='%23FDDA24' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.item-novedad .item-novedad-body {
    padding: 15px;
    border-radius: 25px;
    box-shadow: 0px 0px 15px #ccc;
    font-size: 14px;
    font-weight: 400;
    color: #000;
    line-height: 2;
    text-align: justify;
    margin-top: 15px;
    height: 0px;
    padding: 0px;
    overflow: hidden;
    transition: height 0.5s, padding 0.5s;
    background: #fff;
}

.item-novedad.open .item-novedad-body {
    height: auto;
    padding: 15px;
    overflow: initial;
    transition: height 0.5s, padding 0.5s;
}

.lbl-error {
    color: #FF5D5D;
    font-size: 11px;
    padding: 6px 2px;
    text-align: center;
    display: block;
    font-weight: 700;
}

html {
    min-height: 100%;
}

body {
    min-height: 100%;
    background: url('./globalassets/img/bg-body.svg') no-repeat;
    background-position: bottom 15px center;
    background-size: 100%;
    background-attachment: fixed;
    object-fit: cover;
}

body.remove-bg {
    background: #fff;
}

.text-left {
    text-align: left;
}