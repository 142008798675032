.txt-select {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.2px;
    color: #000000;
    margin: 0px;
    padding: 0px;
}

select.select-departamento {
    border: none;
    border-bottom: 2px solid;
    width: 40%;
}

.select-departamento:focus {
    border-bottom: 2px solid #fdda24;
}

select.select-departamento:focus-visible {
    outline: none !important;
}

.container-select {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.container-map {
    display: flex;
    justify-content: center;
}

.txt-title-graphic {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 18px;
    letter-spacing: -0.2px;
    color: #000000;
    font-weight: 700;
    margin: 0px -15px 15px -15px;
}

.border-div-title {
    border-bottom: 2px solid #e4e4e4;
    width: 78%;
    margin: 0 auto;
}

.container-graphic {
    display: flex;
    justify-content: center;
}

.mt-2.container-info {
    /*position: fixed;*/
    bottom: 15px;
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 15px 0px;
}

.Container-card-info {
    box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px;
    width: 85%;
    margin: 0 auto;
    padding: 5px 15px;
    border-radius: 10px;
    background: #fff;
}

.title-txt-cajero {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.2px;
    color: #000000;
    border-bottom: 1px solid #000;
    margin-bottom: 3px;
}

img.img-tuerca {
    width: 25px;
    height: 25px;
}

.txt-falla {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.2px;
    color: #000000;
    margin: auto 0px auto 7px;
}

.txt-falla-2 {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.2px;
    color: #000000;
    margin: 4px 0;
}

.container-card-falla {
    width: 55%;
}

.container-card-falla-2 {
    width: 45%;
}

.link-bottom {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.2px;
    text-decoration-line: underline;
    color: #000000;
    margin: auto 0px auto 7px;
}

.grafica-container {
    width: 100%;
}

.mapa-alertas {
    position: absolute;
    right: 0px;
    top: 0px;
}

.mapa-alertas .box-alert {
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 8px;
    background: #28B4E0;
    margin-bottom: 10px;
    text-align: center;
    cursor: pointer;
}

.mapa-alertas .box-alert:last-child {
    background: #0BA783;
}