.date-app {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.2px;
  color: #000000;
}

.container-tab {
  justify-content: center;
  display: flex;
  align-items: center;
  border-bottom: solid 3px #58595b;
  width: 50%;
}

.txt-tabs {
  
  font-family: "Open Sans", sans-serif;
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.2px;
  color: #000000;
}

.active-tabs {
  border-bottom: solid 3px #fada22;
}

.active-tabs p {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.2px;
  color: #000000;
}

.Content-tab {
  display: none;
}

.active-content {
  display: block;
}

.backgroundImage {
  position: fixed;
  z-index: -1;
  bottom: 4rem;
  width: 100%;
}

.Card-info {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  width: 37%;
  padding: 21px 16px;
  align-items: center;
  justify-content: flex-start;
  height: 130px;
}

.Card-info2 {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  width: 37%;
  padding: 21px 6px;
  align-items: center;
  justify-content: flex-start;
  height: 130px;
}

.number-info {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 18px;
  letter-spacing: -0.2px;
  color: #000000;
}

.txt-info {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.2px;
  color: #000000;
}

.Container-card {
  display: flex;
  justify-content: center;
  padding: 0 10px;
}

.card-color {
  background: #00ccea;
}

.card-color2 {
  background: #0ba783;
}

.card-color3 {
  background: #f5b6cd;
}

.margin-div {
  margin-left: 20px;
}
