.modal-alert .modal-footer .btn {
    background: #fada22;
    border: none;
    border-radius: 10px;
    width: 206px;
    height: 33px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.2px;
    color: #000000;
    font-weight: bold;
}

.modal-alert .modal-body {
    color: #000;
    font-size: 14px;
    text-align: center;
}

.modal-alert.error .modal-footer .btn {
    background: #fada22;
    color: #fff;
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.2px;
    color: #000000;
}

.modal-alert .bar-error {
    background: #dc777f;
    padding: 15px;
    text-align: center;
    border-radius: 7px 7px 0px 0px;
}

.modal-alert .bar-error {
    background: #dc777f;
    padding: 15px;
    text-align: center;
    border-radius: 7px 7px 0px 0px;
    display: none;
}

.modal-footer {
    border: none;
}

.modal-alert .modal-body {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.2px;
    color: #000000;
}

.modal-content {
    box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px;
}