.container-tab-2 {
  border-bottom: solid 2px #d9d9d9;
  width: 50%;
  margin: 0 5px;
  position: relative;
}

.active-tabs-2 {
  border-bottom: solid 3px #fada22;
}

.txt-tabs-2 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  letter-spacing: -0.2px;
  color: #000000;
  margin-bottom: 0px;
  text-align: center;
}

img.location-2 {
  width: 20px;
  height: 20px;
  position: absolute;
  left: -10px;
  top: -4px;
}

.txt-title-ubi-2 {
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: -0.2px;
  color: #000000;
  margin-bottom: 0;
}

img.graphic {
  width: 15px;
}

.txt-info-bottom {
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: -0.2px;
  color: #000000;
  margin: 0;
  margin-left: 5px;
}