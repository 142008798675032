.svg-container {
    height: 320px;
    width: 100%;
    position: relative;
}

.svg-container svg {
    width: 100%;
}

.svg-container .bullet-falla {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    vertical-align: middle;
    background: #F07F4A;
    color: #fff;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    pointer-events: none;
}

.pos-SA {
    top: 76px;
    left: 110px;
}

.pos-AH {
    top: 108px;
    left: 35px;
}

.pos-SO {
    top: 140px;
    left: 80px;
}

.pos-CH {
    top: 38px;
    left: 190px;
}

.pos-LI {
    top: 140px;
    left: 142px;
}

.pos-SS {
    top: 130px;
    left: 180px;
}

.pos-CU {
    top: 130px;
    left: 218px;
}

.pos-CA {
    top: 95px;
    left: 280px;
}

.pos-PA {
    top: 175px;
    left: 220px;
}

.pos-SV {
    top: 180px;
    left: 266px;
}

.pos-US {
    top: 188px;
    left: 315px;
}

.pos-SM {
    top: 170px;
    left: 365px;
}

.pos-MO {
    top: 120px;
    left: 400px;
}

.pos-UN {
    top: 195px;
    left: 435px;
}