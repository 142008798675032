.check-default {
    position: relative;
    zoom: 0.5;
}

.check-default:before {
    zoom: 2;
    content: '';
    position: absolute;
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 10px;
    background: #D9D9D9;
    margin-top: -4px;
    margin-left: -2px;
}

.check-default:checked:after {
    zoom: 2;
    content: '';
    position: absolute;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-top: -8px;
    margin-left: -2px;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.34795 0.225571C8.4034 0.168994 8.46958 0.124047 8.54262 0.0933635C8.61565 0.0626796 8.69408 0.046875 8.7733 0.046875C8.85252 0.046875 8.93094 0.0626796 9.00397 0.0933635C9.07701 0.124047 9.14319 0.168994 9.19864 0.225571C9.43102 0.460384 9.43427 0.839821 9.20677 1.0787L4.40245 6.75807C4.34791 6.81797 4.28171 6.86611 4.20792 6.89955C4.13413 6.93298 4.05429 6.95101 3.9733 6.95254C3.8923 6.95406 3.81184 6.93904 3.73685 6.90839C3.66185 6.87775 3.5939 6.83213 3.53714 6.77432L0.613766 3.81195C0.501021 3.69697 0.437866 3.54236 0.437866 3.38132C0.437866 3.22029 0.501021 3.06568 0.613766 2.9507C0.669216 2.89412 0.735396 2.84917 0.808432 2.81849C0.881467 2.7878 0.95989 2.772 1.03911 2.772C1.11833 2.772 1.19675 2.7878 1.26979 2.81849C1.34282 2.84917 1.409 2.89412 1.46445 2.9507L3.9442 5.46376L8.3317 0.243446C8.33676 0.237167 8.34218 0.231198 8.34795 0.225571Z' fill='%23222222'/%3E%3C/svg%3E");
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
}