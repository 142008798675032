.navbar {
    background-color: transparent;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    top: 10px;
    left: 2rem;
}

.menu-bars {
    margin-left: 1rem;
    font-size: 2rem;
    background: none;
}

.nav-menu {
    background-color: #fff;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    z-index: 99;
    box-shadow: 0px 0px 15px #000;
}

.nav-menu.active {
    left: 0;
    transition: 350ms;
}

.nav-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0px 7px 0px;
    list-style: none;
    height: 60px;
    border-bottom: 1px solid #000;
}

.nav-text-2 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0px 7px 0px;
    list-style: none;
    height: 60px;
}

.nav-text a {
    font-family: "Open Sans", sans-serif;
    text-decoration: none;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.2px;
    color: #000000;
}

.nav-text-2 a {
    font-family: "Open Sans", sans-serif;
    text-decoration: none;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.2px;
    color: #000000;
}

.nav-menu-items {
    width: 100%;
    padding: 0 25px;
}

.navbar-toggle {
    background-color: transparent;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    top: -11px;
    left: -15px;
}

span {
    margin: 0px 5px;
}

.nav-text.nav-text-1 {
    margin-top: 3rem;
}