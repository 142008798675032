.splash-screen {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: #fff;
    background-image: url('trazo.svg');
    background-size: 110%;
    background-repeat: no-repeat;
    background-position: bottom 40px center;
    opacity: 1;
    animation-delay: 1500ms;
    animation-duration: 2s;
    animation-name: fadeOut;
    animation-fill-mode: forwards;
}

.splash-body {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
}

.splash-body img {
    max-width: 100%;
    max-height: 35px;
}

.splash-body h2 {
    font-weight: bold;
    font-size: 22px;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    99% {
        opacity: 0.01;
        width: 100%;
        height: 100%;
    }
    100% {
        opacity: 0;
        width: 0;
        height: 0;
    }
}